//COLORS

$light_gray: #969BA1;
$spanish-gray: #909498;
$lighter-gray: #D0D3D4;
$extra_light_gray: #F7F9FA;
$light-text-text-on-color-disabled: #85888A;
$light-text-text-secondary: #6E7781;
$lighter-data-orange: #F49A4C;
$light-data-orange: #CB4D00;
$light-data-sky-blue: #009CEA;
$dark-data-data-blue-200: #90CAF9;
$light-data-deep-blue: #4632FF;
$darkmode-basic-colors-purple: #CC78FF;
$light-data-lin-purple: #8A64BC;
$light-data-mint: #00AA61;
$text-disabled: #C0C0C0;
$basic-color-dark-gray: #6c7678;
$basic-color-gray-font: #374649;
$form-light-background:#f7f9fa;
$yellow-badge-background: #F3D9B3;
$blue-badge-background: rgba(0, 105, 92, 0.3);
$data-deep-orange: #FF5722;

//readiness status colors
$data-teal-200: #80CBC4;
$data-teal-800: #00695C;
$data-grass-200:#C5E1A5;
$orange-awating_4m:#EF6F00;
$blue-awaiting_5w:#009CEA;
$purple-awaiting_2wf:#CC78FF;
$rose-background-alert:#FF9789;
$green-layer-01: #40A900;
$layer-02-active:#3B3B43;
$dark-text-primary: #222B2D;
$dark-green: #06402B;
$gray:#8c9294;

$light-gray-button-background: rgba(34, 43, 45, 0.3);
$gray-button-background: rgba(34, 43, 45, 0.5);
