/* You can add global styles to this file, and also import other style files */

@import "~@totalenergiescode/design-system/dist/css/angular-ng-select/ng-select.css";
@import "~@ag-grid-community/styles/ag-grid.css";
@import "~@ag-grid-community/styles/ag-theme-alpine.css";
@import "./../../../node_modules/ngx-toastr/toastr.css";
@import "./styles/jbid";
@import "~@totalenergiescode/design-system/dist/css/totalenergies-design-system.css";

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }
.cdk-overlay-container {
  z-index: 100000 !important;
}

.underline-cell {
  text-decoration: underline;
  cursor: pointer;
  color: #ED0000;
}
