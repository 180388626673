@import './variables';

html,
body {
  height: 100%;
}

body {
  margin: 0; /*removes default style*/
}

:focus {
  outline: none;
}

// Design System v1 issue with icons inside button not btn-circle
.btn:not(.btn-circle) [class^="material-icons"],
.btn .spinner-border.spinner-border-sm {
  margin-right: 0 !important;
}

/* Forms */
select {
  &.form-control-plaintext {
    pointer-events: none !important;
    appearance: none !important;
  }
}

.input-group.jbid-datepicker .btn {
  border-radius: 0 0.5rem 0.5rem 0 !important;

  // Design System v1 issue with icons inside button not btn-circle
  .material-icons,
  .material-icons-outlined {
    margin-right: 0 !important;
  }
}

.form-control-plaintext {
  padding-top: 0;
}

.form-error-tooltip {
  /* noop for now */
}

.form-label {
  font-size: 0.8125rem;
  margin-bottom: 0;
}

.form-check > label {
  margin-right: 0.8125rem;
}

.form-label.required:before,
.table th.required:before {
  color: var(--bs-red);
  content: "*";
}

.table th.required:before {
  margin-right: 3px;
}

/* Resources Table / Array */

.overflow-x-scroll {
  overflow-x: scroll;
}

.table th:not(:first-of-type),
.table td:not(:first-child) {
  border-bottom: 1px solid var(--bs-gray-300) !important;
}


.mat-mdc-progress-bar .mdc-linear-progress__buffer-bar {
  background-color: var(--mdc-linear-progress-track-color);
}

.gray-progress {
  --mdc-linear-progress-active-indicator-color: #{$light_gray}; //progress bar color
  --mdc-linear-progress-track-color: #{$light-text-text-secondary}; // background color
  --mdc-linear-progress-track-height: 4px;

}

.green-progress {
  --mdc-linear-progress-active-indicator-color: #{$light-data-mint}; //progress bar color
  --mdc-linear-progress-track-color: #{$light-data-mint}; // background color
  --mdc-linear-progress-track-height: 4px;

}

.orange-progress {
  --mdc-linear-progress-active-indicator-color: #{$lighter-data-orange}; //progress bar color
  --mdc-linear-progress-track-color: #{$light-data-orange}; // background color
  --mdc-linear-progress-track-height: 4px;

}

.blue-progress {
  --mdc-linear-progress-active-indicator-color: #{$light-data-sky-blue}; //progress bar color
  --mdc-linear-progress-track-color: #{$light-data-deep-blue}; // background color
  --mdc-linear-progress-track-height: 4px;

}

.violet-progress {
  --mdc-linear-progress-active-indicator-color: #{$darkmode-basic-colors-purple}; //progress bar color
  --mdc-linear-progress-track-color: #{$light-data-lin-purple}; // background color
  --mdc-linear-progress-track-height: 4px;

}

.empty-progress {
  --mdc-linear-progress-active-indicator-color: #edeff0; //progress bar color
  --mdc-linear-progress-track-color:  #edeff0; // background color
  --mdc-linear-progress-track-height: 4px;
}

.empty-buffer{
  --mdc-linear-progress-active-indicator-color: #edeff0; //progress bar color
  --mdc-linear-progress-track-color:  #edeff0; // background color
  --mdc-linear-progress-track-height: 4px;
}

.badge-yellow {
  background-color: $yellow-badge-background;
  color: black;
}

.badge-blue {
  background-color: $blue-badge-background;
  color: black;
}

.badge-blue-sky {
  background-color: $dark-data-data-blue-200;
  color: black;
}

.readiness-ready-to-activate {
  background-color: $data-teal-200;
  color: $dark-text-primary;
}

.readiness-draft {
  border: 2px solid $data-teal-200;
  padding-bottom: 1rem;
  color: $dark-text-primary;
}

.readiness-awaiting-4m {
  border: 2px solid $orange-awating_4m;
  padding-bottom: 1rem;
  color: $dark-text-primary;
}

.readiness-ready-4m {
  background-color: $orange-awating_4m;
}

.readiness-awaiting-5w {
  border: 2px solid $blue-awaiting_5w;
  padding-bottom: 1rem;
  color: $dark-text-primary;
}

.readiness-ready-5w {
  background-color: $blue-awaiting_5w;
  color: $dark-text-primary;
}

.readiness-awaiting-2w {
  border: 2px solid $purple-awaiting_2wf;
  padding-bottom: 1rem;
  color: $dark-text-primary;
}

.readiness-ready-2w {
  background-color: $purple-awaiting_2wf;
  color: $dark-text-primary;
}

.readiness-site-prep {
  border: 2px solid $green-layer-01;
  padding-bottom: 1rem;
  color: $dark-text-primary;
}

.readiness-site-exec {
  background-color: $green-layer-01;
  color: $dark-text-primary;
}

.readiness-site-prep-disabled {
  border: 2px solid $gray;
  padding-bottom: 1rem;
  color: $dark-text-primary;
}

.readiness-site-exec-disabled {
  background-color: $gray;
  color: $dark-text-primary;
}

.readiness-late-exec {
  background-color: $data-teal-800;
  color: #fff;
}

.readiness-cancelled {
  background-color: $rose-background-alert;
  color: $dark-text-primary;
}

.readiness-black {
  background-color: black;
}

.text-alert-orange {
  color: $data-deep-orange;
}

.filter-container {
  background-color: $extra_light_gray;
  padding: 1rem;
  border-radius: 1rem;
}

.badge-height {
  height: 21px;
}
